import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, Col, CardTitle } from "reactstrap"
import { getMiles } from "helpers/utilHelper";
import { getNotaryBidStatusBadge, getNotaryBidStatusLabel } from "helpers/jsxHelper";
import classnames from "classnames";
import OrderServicesSection from "components/Shared/OrderServicesSection";

const NotaryBidCardMobile = ({ notaryBid, children, isDetailed }) => {

  return <Card className="mav-card notary-bid-card mobile-card expand-v" >
    <CardHeader className="mav-card-header">
      <div className="d-flex justify-content-between align-center mb-2">
        <CardTitle className="mav-card-title">{getNotaryBidStatusLabel(notaryBid.isExpired, notaryBid.status)}</CardTitle>
        {getNotaryBidStatusBadge(notaryBid.isExpired, notaryBid.status)}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        {!isDetailed && <Col xs="auto" className="basic-info-col d-flex align-items-center">
          <i className="bx bxs-map-pin" />
          <span>{`${getMiles(notaryBid.distance)} miles away`}</span>
        </Col>}
      </div>
    </CardHeader>

    <CardBody className="mav-card-body">
      <div className="p-0 fw-bold">
        Order ID: #{notaryBid.orderId}
      </div>
      <div className="font-weight-500 mb-2">Dealers Instructions:</div>
      <div className={classnames("dealer-instructions mb-2", { "text-muted": !notaryBid.dealerInstructions })}>
        {notaryBid.dealerInstructions || "No dealer instructions"}
      </div>
      <OrderServicesSection order={notaryBid} />
      {children}
    </CardBody>
  </Card>
}

NotaryBidCardMobile.propTypes = {
  notaryBid: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDetailed: PropTypes.bool,
};

export default NotaryBidCardMobile;