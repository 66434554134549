import React from "react";
import NotaryBid from "model/notaryBid";

export const getNotaryBidStatusBadge = (isExpired, status) => {
  if (isExpired && status <= NotaryBid.STATUS_NOTIFIED) {
    return <span className={"badge badge-lg rounded-pill bg-danger"}>Expired</span>
  }
  switch (status) {
    case NotaryBid.STATUS_NOTIFIED:
      return <span className={"badge badge-lg rounded-pill bg-success"}>New</span>
    case NotaryBid.STATUS_ACCEPTED_BY_NOTARY:
      return <span className={"badge badge-lg rounded-pill bg-warning"}>Pending</span>
    case NotaryBid.STATUS_DECLINED_BY_NOTARY:
      return <span className={"badge badge-lg rounded-pill bg-danger"}>Declined</span>
    case NotaryBid.STATUS_AWARDED_BY_SCHEDULER:
      return <span className={"badge badge-lg rounded-pill bg-info"}>Awarded</span>
    case NotaryBid.STATUS_REJECTED_BY_SCHEDULER:
    case NotaryBid.STATUS_NOT_AWARDED:
      return <span className={"badge badge-lg rounded-pill bg-secondary"}>Not Awarded</span>
    case NotaryBid.STATUS_CLOSED_BY_AWARD:
      return <span className={"badge badge-lg rounded-pill bg-secondary"}>Expired</span>
  }
}

export const getNotaryBidStatusLabel = (isExpired, status) => {
  if (isExpired) {
    return "This lead is expired"
  }
  switch (status) {
    case NotaryBid.STATUS_NOTIFIED:
    case NotaryBid.STATUS_ACCEPTED_BY_NOTARY:
    case NotaryBid.STATUS_DECLINED_BY_NOTARY:
      return "This lead is active"
    case NotaryBid.STATUS_AWARDED_BY_SCHEDULER:
    case NotaryBid.STATUS_REJECTED_BY_SCHEDULER:
    case NotaryBid.STATUS_NOT_AWARDED:
    case NotaryBid.STATUS_CLOSED_BY_AWARD:
      return "This lead is expired"
  }
}