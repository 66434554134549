import React from "react";
import PropTypes from "prop-types";
import { BrowserView, MobileView } from "react-device-detect";
import NotaryBidCardDesktop from "../Desktop/NotaryBidCard";
import NotaryBidCardMobile from "../Mobile/NotaryBidCard";

const NotaryBidCard = ({ notaryBid, children, isDetailed }) => {

  return <>{notaryBid && <React.Fragment>
    {/* Desktop Content */}
    <BrowserView className="h-100">
      <NotaryBidCardDesktop notaryBid={notaryBid} isDetailed={isDetailed}>{children}</NotaryBidCardDesktop>
    </BrowserView>

    {/* Mobile Content */}
    <MobileView>
      <NotaryBidCardMobile notaryBid={notaryBid} isDetailed={isDetailed}>{children}</NotaryBidCardMobile>
    </MobileView>
  </React.Fragment>}</>
}

NotaryBidCard.propTypes = {
  notaryBid: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDetailed: PropTypes.bool,
};

export default NotaryBidCard;


