import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, Row, Col, CardTitle } from "reactstrap"
import { getMiles } from "helpers/utilHelper";
import { getNotaryBidStatusBadge, getNotaryBidStatusLabel } from "helpers/jsxHelper";
import classnames from "classnames";
import OrderServicesSection from "components/Shared/OrderServicesSection";

const NotaryBidCardDesktop = ({ notaryBid, children, isDetailed }) => {

  return <Card className="mav-card notary-bid-card expand-v" >
    <CardHeader className="mav-card-header">
      <div className="d-flex justify-content-between align-center">
        <CardTitle className="mav-card-title">{getNotaryBidStatusLabel(notaryBid.isExpired, notaryBid.status)}</CardTitle>
        <div className="mb-1">
          {getNotaryBidStatusBadge(notaryBid.isExpired, notaryBid.status)}
        </div>
      </div>
      <div className="mav-card-subtitle">
        You have been selected to bid on this order since our customer is in your proximity. Please respond promptly as other notaries have been inquired as well.
      </div>
    </CardHeader>
    <CardBody className="mav-card-body h-100">
      <Row className="notary-bid-basic-info justify-content-between mb-3">
        {!isDetailed && <Col xs="auto" className="d-flex align-items-end flex-column font-size-12">
          <div>
            <i className="bx bxs-map-pin" />
            <span>{`${getMiles(notaryBid.distance)} miles away`}</span>
          </div>
        </Col>}
      </Row>
      <Row className="mb-3 p-2">
        <div className="p-0 fw-bold">
          Order ID: #{notaryBid.orderId}
        </div>
        <div className="p-0 fw-bold mb-2">
          {"Dealer's instructions:"}
        </div>
        <div className={classnames("dealer-instructions", { "text-muted": !notaryBid.dealerInstructions })}>
          {notaryBid.dealerInstructions || "No instructions"}
        </div>
      </Row>
      <OrderServicesSection order={notaryBid} />
      {children}
    </CardBody>
  </Card>
}

NotaryBidCardDesktop.propTypes = {
  notaryBid: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDetailed: PropTypes.bool
};

export default NotaryBidCardDesktop;